<template>
  <div>
    <a-row>
      <a-col :lg="24">
        <a-tabs
          class="tabs-sliding"
          type="line"
          :activeKey="activeKey"
          @change="onChangeActiveKey"
        >
          <a-tab-pane key="1" :tab="$t('arbitrage.Strategy_1')">
            <a-card :bordered="true">
              <template #title>
                <h6>{{ $t("sidebar.arbitrage") }}</h6>
              </template>
              <template #extra>
                <a-button
                  class="m-5"
                  size="small"
                  type="primary"
                  @click="createBtn1"
                >
                  <a-icon type="plus" />
                  Create New Configration
                </a-button>
              </template>
              <a-table
                :columns="columns"
                :data-source="arbitrage_1_setting_data"
                :pagination="false"
                size="small"
                :loading="loading"
              >
                <template slot="currency" slot-scope="text, record">
                  {{ record.coin | toUpperCase }}/{{
                    record.pair | toUpperCase
                  }}
                </template>
                <template slot="isRun" slot-scope="text, record">
                  <a-switch
                    :checked="text === 'running'"
                    @change="isRunChange1(text, record)"
                  />
                </template>
                <template slot="editBtn" slot-scope="text, record">
                  <a-button type="link" @click="editBtnHandler1(text, record)">
                    <a-icon type="edit" theme="twoTone" />
                  </a-button>
                  <a-divider type="vertical" />
                  <a-button
                    type="link"
                    @click="deleteBtnHandler1(text, record)"
                  >
                    <a-icon type="delete" theme="twoTone" />
                  </a-button>
                </template>
              </a-table>
            </a-card>
          </a-tab-pane>
          <!-- 전략2 -->
          <a-tab-pane key="2" :tab="$t('arbitrage.Strategy_2')">
            <a-card :bordered="true">
              <template #title>
                <h6>{{ $t("sidebar.arbitrage") }}</h6>
              </template>
              <template #extra>
                <a-button
                  class="m-5"
                  size="small"
                  type="primary"
                  @click="createBtn2"
                >
                  <a-icon type="plus" />
                  Create New Configration
                </a-button>
              </template>
              <a-table
                :columns="columns"
                :data-source="arbitrage_2_setting_data"
                :pagination="false"
                size="small"
                :loading="loading"
                defaultExpandAllRows
              >
                <template slot="currency" slot-scope="text, record">
                  {{ record.coin | toUpperCase }}/{{
                    record.pair | toUpperCase
                  }}
                </template>
                <template slot="isRun" slot-scope="text, record">
                  <a-switch
                    :checked="text === 'running'"
                    @change="isRunChange2(text, record)"
                  />
                </template>
                <template slot="editBtn" slot-scope="text, record">
                  <a-button type="link" @click="editBtnHandler2(text, record)">
                    <a-icon type="edit" theme="twoTone" />
                  </a-button>
                  <a-divider type="vertical" />
                  <a-button
                    type="link"
                    @click="deleteBtnHandler2(text, record)"
                  >
                    <a-icon type="delete" theme="twoTone" />
                  </a-button>
                </template>
                <template slot="expandedRowRender" slot-scope="record">
                  <a-row type="flex" justify="space-between" align="center">
                    <a-col :span="12">
                      <a-descriptions size="small">
                        <a-descriptions-item label="Current Profit">
                          {{
                            record.status === "running"
                              ? `${record.info_profit.toFixed(4)} %` || "-"
                              : "(stop)"
                          }}
                        </a-descriptions-item>
                        <a-descriptions-item label="Open Positions">
                          {{
                            record.state && record.state.open_orders ? record.state.open_orders.length : 0
                          }}
                        </a-descriptions-item>
                      </a-descriptions>
                    </a-col>
                    <a-col>
                      <a-button
                        @click="resetStatus(2, record._id)"
                        v-if="record.status === 'stop'"
                      >
                        Reset State
                      </a-button>
                    </a-col>
                  </a-row>
                </template>
              </a-table>
            </a-card>
          </a-tab-pane>
          <!-- 전략3 -->
          <a-tab-pane key="3" :tab="$t('arbitrage.Strategy_3')">
            <a-card :bordered="true">
              <template #title>
                <h6>{{ $t("sidebar.arbitrage") }}</h6>
              </template>
              <template #extra>
                <a-button
                  class="m-5"
                  size="small"
                  type="primary"
                  @click="createBtn3"
                >
                  <a-icon type="plus" />
                  Create New Configration
                </a-button>
              </template>
              <a-table
                :columns="columns"
                :data-source="arbitrage_3_setting_data"
                :pagination="false"
                size="small"
                :loading="loading"
                defaultExpandAllRows
              >
                <template slot="currency" slot-scope="text, record">
                  {{ record.coin | toUpperCase }}/{{
                    record.pair | toUpperCase
                  }}
                </template>
                <template slot="isRun" slot-scope="text, record">
                  <a-switch
                    :checked="text === 'running'"
                    @change="isRunChange3(text, record)"
                  />
                </template>
                <template slot="editBtn" slot-scope="text, record">
                  <a-button type="link" @click="editBtnHandler3(text, record)">
                    <a-icon type="edit" theme="twoTone" />
                  </a-button>
                  <a-divider type="vertical" />
                  <a-button
                    type="link"
                    @click="deleteBtnHandler3(text, record)"
                  >
                    <a-icon type="delete" theme="twoTone" />
                  </a-button>
                </template>
                <template slot="expandedRowRender" slot-scope="record">
                  <a-row type="flex" justify="space-between" align="center">
                    <a-col :span="18">
                      <a-descriptions size="small">
                        <a-descriptions-item label="Current Profit">
                          {{
                            record.status === "running"
                              ? `${record.info_profit.toFixed(4)} %` || "-"
                              : "(stop)"
                          }}
                        </a-descriptions-item>
                        <a-descriptions-item label="Current Funding Profit">
                          {{
                            record.status === "running" && record.info_funding
                              ? record.info_funding.total_funding_profit || "-"
                              : "(stop)"
                          }}
                        </a-descriptions-item>
                        <a-descriptions-item label="Next Funding Profit">
                          {{
                            record.status === "running" && record.info_funding
                              ? record.info_funding.funding_profit_predict.toFixed(6) || "-"
                              : "(stop)"
                          }}
                        </a-descriptions-item>
                        <a-descriptions-item label="Open Positions">
                          {{
                            record.state ? viewUnitPrice(record.state) : ''
                          }}
                        </a-descriptions-item>
                      </a-descriptions>
                    </a-col>
                    <a-col>
                      <a-button
                        @click="resetStatus(3, record._id)"
                        v-if="record.status === 'stop'"
                      >
                        Reset State
                      </a-button>
                    </a-col>
                  </a-row>
                </template>
              </a-table>
            </a-card>
          </a-tab-pane>
        </a-tabs>
      </a-col>
    </a-row>
    <a-drawer
      :title="isEnableEdit ? 'Modify' : 'Add'"
      :width="720"
      :visible="isEdit"
      @close="onClose"
    >
      <arbitrage-edit1
        v-if="activeKey === '1'"
        ref="editComp1"
      ></arbitrage-edit1>
      <arbitrage-edit2
        v-if="activeKey === '2'"
        ref="editComp2"
      ></arbitrage-edit2>
      <arbitrage-edit3
        v-if="activeKey === '3'"
        ref="editComp3"
      ></arbitrage-edit3>
    </a-drawer>
  </div>
</template>
<script>
import arbitrageEdit1 from "./arbitrageEdit1.vue";
import arbitrageEdit2 from "./arbitrageEdit2.vue";
import arbitrageEdit3 from "./arbitrageEdit3.vue";

export default {
  components: {
    arbitrageEdit1,
    arbitrageEdit2,
    arbitrageEdit3,
  },

  data() {
    return {
      activeKey: "1",
      columns: [
        {
          title: "name",
          dataIndex: "name",
        },
        {
          title: "APIKEY_1",
          dataIndex: "apikey_1_name",
        },
        {
          title: "APIKEY_2",
          dataIndex: "apikey_2_name",
        },
        {
          title: "Exchange_1",
          dataIndex: "exchange_1",
        },
        {
          title: "Exchange_2",
          dataIndex: "exchange_2",
        },
        {
          title: "Currency",
          scopedSlots: { customRender: "currency" },
        },
        {
          title: "run",
          dataIndex: "status",
          scopedSlots: { customRender: "isRun" },
        },
        {
          title: "",
          scopedSlots: { customRender: "editBtn" },
          width: 150,
        },
      ],
      arbitrage_1_setting_data: [],
      arbitrage_2_setting_data: [],
      arbitrage_3_setting_data: [],
      isEdit: false,
      loading: true,
      isEnableEdit: false,
      updateInterval: null,
    };
  },
  filters: {
    toUpperCase(value) {
      return value.toUpperCase();
    },
  },
  async mounted() {
    const [arbitrage1, arbitrage2, arbitrage3] =
      await this.getArbitrageConfig();
    this.arbitrage_1_setting_data = arbitrage1;
    this.arbitrage_2_setting_data = arbitrage2;
    this.arbitrage_3_setting_data = arbitrage3;
    this.loading = false;
    this.updateInterval = setInterval(() => {
      this.refreshArbitrageConfig();
    }, 5 * 1000);
  },
  methods: {
    viewUnitPrice(state) {
      // 진입현황(평단가)
      return `SPOT: ${state.spot_open_unit_price}/${state.spot_open_unit} FUTURE: ${state.future_open_unit_price}/${state.future_open_unit}`
    },
    getFundingInfo(info_profit){
      return 
    },
    async resetStatus(arbitrage_number, id) {
      try {
        const state = {
          spot_open_unit_price:  0,
          spot_open_unit: 0,
          future_open_unit_price:  0,
          future_open_unit:  0,
        };
        const res = await this.$http.put(
          `/api/v1/autotrade/arbitrage${arbitrage_number}/${id}`,
          { state, info_profit: 0 }
        );
        this.$notification.open({
          message: `Success`,
          placement: "bottomLeft",
        });
        await this.refreshArbitrageConfig();
      } catch (e) {
        console.error(e.response);
      }
    },
    async refreshArbitrageConfig() {
      // this.loading = true;
      const [arbitrage1, arbitrage2, arbitrage3] =
        await this.getArbitrageConfig();
      this.arbitrage_1_setting_data = arbitrage1;
      this.arbitrage_2_setting_data = arbitrage2;
      this.arbitrage_3_setting_data = arbitrage3;
      this.loading = false;
    },
    async getArbitrageConfig() {
      const endpoints = [
        "/api/v1/autotrade/arbitrage1/",
        "/api/v1/autotrade/arbitrage2/",
        "/api/v1/autotrade/arbitrage3/",
      ];
      const promises = endpoints.map((endpoint) => this.$http.get(endpoint));
      const results = await Promise.all(promises);
      return results.map((res) => res.data);
    },
    onClose() {
      this.isEdit = false;
    },
    async isRunChange1(text, record) {
      this.loading = true;
      const res = await this.$http.get(
        `/api/v1/autotrade/arbitrage1/run/${record._id}`
      );
      record.status = res.data;
      this.loading = false;
    },
    async isRunChange2(text, record) {
      this.loading = true;
      const res = await this.$http.get(
        `/api/v1/autotrade/arbitrage2/run/${record._id}`
      );
      record.status = res.data;
      this.loading = false;
    },
    async isRunChange3(text, record) {
      this.loading = true;
      const res = await this.$http.get(
        `/api/v1/autotrade/arbitrage3/run/${record._id}`
      );
      record.status = res.data;
      this.loading = false;
    },
    editBtnHandler1(text, record) {
      this.isEdit = true;
      this.isEnableEdit = true;
      try {
        this.$refs.editComp1.createEdit(record);
      } catch (e) {
        setTimeout(() => {
          this.$refs.editComp1.createEdit(record);
        }, 500);
      }
    },
    editBtnHandler2(text, record) {
      this.isEdit = true;
      this.isEnableEdit = true;
      try {
        this.$refs.editComp2.createEdit(record);
      } catch (e) {
        setTimeout(() => {
          this.$refs.editComp2.createEdit(record);
        }, 500);
      }
    },
    editBtnHandler3(text, record) {
      this.isEdit = true;
      this.isEnableEdit = true;
      try {
        this.$refs.editComp3.createEdit(record);
      } catch (e) {
        setTimeout(() => {
          this.$refs.editComp3.createEdit(record);
        }, 500);
      }
    },
    async deleteBtnHandler1(text, record) {
      try {
        const res = await this.$http.delete(
          `/api/v1/autotrade/arbitrage1/${record._id}`
        );
        this.refreshArbitrageConfig();
      } catch (e) {
        console.error("삭제하지못함");
      }
    },
    async deleteBtnHandler2(text, record) {
      try {
        const res = await this.$http.delete(
          `/api/v1/autotrade/arbitrage2/${record._id}`
        );
        this.refreshArbitrageConfig();
      } catch (e) {
        console.error("삭제하지못함");
      }
    },
    async deleteBtnHandler3(text, record) {
      try {
        const res = await this.$http.delete(
          `/api/v1/autotrade/arbitrage3/${record._id}`
        );
        this.refreshArbitrageConfig();
      } catch (e) {
        console.error("삭제하지못함");
      }
    },
    createBtn1() {
      this.isEdit = true;
      this.isEnableEdit = false;

      try {
        this.$refs.editComp1.createAdd();
      } catch (e) {
        setTimeout(() => {
          this.$refs.editComp1.createAdd();
        }, 500);
      }
    },
    createBtn2() {
      this.isEdit = true;
      this.isEnableEdit = false;

      try {
        this.$refs.editComp2.createAdd();
      } catch (e) {
        setTimeout(() => {
          this.$refs.editComp2.createAdd();
        }, 500);
      }
    },
    createBtn3() {
      this.isEdit = true;
      this.isEnableEdit = false;

      try {
        this.$refs.editComp3.createAdd();
      } catch (e) {
        setTimeout(() => {
          this.$refs.editComp3.createAdd();
        }, 500);
      }
    },
    onChangeActiveKey(key) {
      this.activeKey = key;
      this.refreshArbitrageConfig();
    },
  },
  beforeDestroy() {
    clearInterval(this.updateInterval);
  },
};
</script>
