
export const coinpair_list = [
        'BTC/USDT', 
        'ETH/USDT', 
        'WAN/USDT', 
        'ADA/USDT', 
        'OMG/USDT', 
        'BCH/USDT', 
        'XRP/USDT', 
        'ZIL/USDT', 
        'SNT/USDT', 
        'CVC/USDT', 
        'LINK/USDT', 
        'IOST/USDT', 
        'ZRX/USDT', 
        'KNC/USDT', 
        'MANA/USDT', 
        'CTXC/USDT', 
        'XLM/USDT', 
        'BNB/USDT', 
        'DOGE/USDT', 
        'TRX/USDT', 
        'DAI/USDT', 
        'XTZ/USDT', 
        'ALGO/USDT', 
        'USDC/USDT', 
        'BUSD/USDT', 
        'BAT/USDT', 
        'MKR/USDT', 
        'ENJ/USDT', 
        'BAND/USDT', 
        'COMP/USDT', 
        'KSM/USDT', 
        'DOT/USDT', 
        'NEAR/USDT',
        'SCRT/USDT', 
        'GLM/USDT', 
        'YFI/USDT', 
        'UNI/USDT', 
        'AAVE/USDT', 
        'ALPHA/USDT', 
        'OCEAN/USDT', 
        'SNX/USDT', 
        'SAND/USDT', 
        'BAL/USDT', 
        'CRV/USDT', 
        'GRT/USDT', 
        'MATIC/USDT', 
        'AXS/USDT', 
        'SUSHI/USDT', 
        'IMX/USDT', 
        'DYDX/USDT', 
        'ENS/USDT', 
        'ILV/USDT', 
        'GALA/USDT', 
        'CHZ/USDT', 
        'SOL/USDT', 
        'AVAX/USDT', 
        'FTM/USDT', 
        'LUNA/USDT', 
        'APE/USDT', 
        'HBAR/USDT', 
        'LRC/USDT', 
        'CELO/USDT', 
        'GAL/USDT', 
        'LUNA2/USDT', 
        'OP/USDT', 
        '1INCH/USDT', 
        'ATOM/USDT', 
        'LDO/USDT', 
        'STG/USDT', 
        'FLOW/USDT', 
        'FXS/USDT', 
        'HFT/USDT', 
        'ARB/USDT', 
        'LQTY/USDT', 
        'PERP/USDT', 
        'ID/USDT', 
        'JOE/USDT', 
        'BLUR/USDT'
]
