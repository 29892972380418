<template>
    <div>
        <a-form-model
            ref="ruleForm"
            :model="configData"
            :rules="rules"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 19 }"
            layout="horizontal"
        >
            <a-form-model-item ref="name" label="Name" prop="name">
                <a-input
                    v-model="configData.name"
                    size="small"
                    style="width: 90%"
                />
            </a-form-model-item>

            <a-form-model-item label="Exchange" prop="name">
                <a-select
                    v-model="configData.exchange_1"
                    size="small"
                    style="width: 40%; margin-right: 10%;"
                    @change="changeExchangeSelect($event, 'exchange_1')"
                    >
                    <a-select-option v-for="v in spot_exchange_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                </a-select>
                <a-select
                    v-model="configData.exchange_2"
                    size="small"
                    style="width: 40%"
                    @change="changeExchangeSelect($event, 'exchange_2')"
                >
                    <a-select-option v-for="v in future_exchange_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                </a-select>
            </a-form-model-item>

            <template v-if="configData.exchange_1 && configData.exchange_2">
                <a-form-model-item label="Apikey" prop="name">
                    <a-select
                        v-model="configData.apikey_1"
                        size="small"
                        style="width: 40%; margin-right: 10%;"
                        :loading="is_apikey_loading"
                        >
                        <a-select-option v-for="v in exchange_1_apikey_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                    <a-select
                        v-model="configData.apikey_2"
                        size="small"
                        style="width: 40%"
                        :loading="is_apikey_loading"
                    >
                        <a-select-option v-for="v in exchange_2_apikey_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>

                <a-form-model-item label="Coin/Pair" prop="name">
                    <a-select
                        v-model="configData.coin_pair"
                        size="small"
                        style="width: 90%;"
                        >
                        <a-select-option v-for="v in coinpair_list" :key="v" :value="v">{{ v }}</a-select-option>
                    </a-select>
                </a-form-model-item>

            </template>

            <template v-if="configData.coin_pair">
                <a-divider/>
                <a-form-model-item label="Fee" prop="name">
                    <a-input-number 
                        v-model="configDetailData.fee_1" 
                        size="small"
                        style="width: 40%; margin-right: 10%;"
                    />
                    <a-input-number 
                        v-model="configDetailData.fee_2"
                        size="small"
                        style="width: 40%;"
                    />
                </a-form-model-item>
                <!-- config -->
                <a-form-model
                    :model="configDetailData"
                    :rules="rules"
                    :label-col="{span: 5}"
                    :wrapper-col="{span: 19}"
                    layout="horizontal">



                    <template v-for="form in config_form">
                        <a-form-model-item :label="form.label" prop="name" :key="form.value">
                            <template v-if="form.type === 'number'">
                                <a-input-number
                                    v-model="configDetailData[form.value]"
                                    size="small"
                                    style="width: 90%"
                                    :min="0"
                                    :max="100"
                                    :formatter="value => `${value}%`"
                                    :parser="value => value.replace('%', '')"
                                    v-if="form.filter === 'percent'"
                                />
                                <a-input-number
                                    v-model="configDetailData[form.value]"
                                    size="small"
                                    style="width: 90%"
                                    :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                                    :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                                    v-else
                                />
                            </template>
                            <template v-if="form.type === 'select'">
                                <a-select
                                    v-model="configDetailData[form.value]"
                                    size="small"
                                    style="width: 90%;"
                                >
                                    <a-select-option v-for="v in ['Auto', 'Manual']" :key="v" :value="v">{{ v }}</a-select-option>
                                </a-select>
                            </template>
                        </a-form-model-item>
                        <div v-if="form.value === 'pnl'" style="text-align: right; margin-right: 10%;">{{$t('arbitrage.onetime_fee_label')}} : ( {{(configDetailData.fee_1 || 0) + (configDetailData.fee_2 || 0)}} ) %</div>
                    </template>

                    <a-form-model-item label="Coin Protect" prop="name">
                        <a-input-number 
                            v-model="configDetailData.coin_protect_1" 
                            size="small"
                            style="width: 40%; margin-right: 10%;"
                        />
                        <a-input-number 
                            v-model="configDetailData.coin_protect_2"
                            size="small"
                            style="width: 40%;"
                        />
                    </a-form-model-item>

                    <a-form-model-item label="Pair Protect" prop="name">
                        <a-input-number 
                            v-model="configDetailData.pair_protect_1" 
                            size="small"
                            style="width: 40%; margin-right: 10%;"
                        />
                        <a-input-number 
                            v-model="configDetailData.pair_protect_2"
                            size="small"
                            style="width: 40%;"
                        />
                    </a-form-model-item>

                    <a-form-model-item :label="$t('arbitrage.is_monitoring')" prop="name">
                        <a-switch
                            v-model="configDetailData.is_monitoring"
                        />
                    </a-form-model-item>

                    <template v-if="configDetailData.is_monitoring">
                        <a-divider/>
                        <a-form-model-item label="Telegram" prop="name">
                            <a-select
                                v-model="select_telegram_id"
                                size="small"
                                style="width: 90%"
                            >
                                <a-select-option v-for="v in telegram_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </template>
                </a-form-model>
            </template>


            
            <a-divider/>
            <template v-if="configData.exchange_1 && configData.exchange_2 && configData.apikey_1 && configData.apikey_2 && configData.coin_pair">
                <a-form-model-item label="Current Price" prop="name">
                    <a-input-search 
                        v-model="configData.stand_price_1"
                        style="width: 25%; margin-right: 5%;" 
                        :loading="is_stand_price_loading" 
                        disabled 
                    />
                    <a-input-search 
                        v-model="configData.stand_price_2"
                        style="width: 25%; margin-right: 5%;" 
                        :loading="is_stand_price_loading" 
                        disabled 
                    />
                    <a-input-search
                        v-model="configData.price_calc"
                        style="width: 30%"
                        :loading="is_stand_price_loading"
                        disabled
                    />
                    <a-button type="link" icon="sync" @click="getTicker" />
                </a-form-model-item>

                <a-form-model-item label="Funding Rate" prop="name">
                    <a-input-search 
                        v-model="configData.funding_rate" 
                        style="width: 90%;" 
                        :loading="is_funding_rate_loading" 
                        disabled
                        :formatter="value => `${value}%`"
                        :parser="value => value.replace('%', '')"
                    />
                    <a-button type="link" icon="sync" @click="getFundingRate" />
                </a-form-model-item>

                <a-form-model-item label="Balance(coin/pair)" prop="name">
                    <a-input-search 
                        v-model="configData.balance_1"
                        style="width: 45%; margin-right: 1%;" 
                        :loading="is_balance_loading"
                        disabled 
                    />
                    <a-input-search 
                        v-model="configData.balance_2"
                        style="width: 45%;" 
                        :loading="is_balance_loading"
                        disabled 
                    />
                    <a-button type="link" icon="sync" @click="getBalance" />
                </a-form-model-item>
            </template>
            <a-row>
                <a-col>
                    <span>{{ error_log }}</span>
                </a-col>
                <a-col>
                    <a-button class="m-15" @click="emitBtn">{{ is_edit? 'Modify' : 'Add' }}</a-button>
                </a-col>
            </a-row>
      </a-form-model>
    </div>
</template>
<script>
import { coinpair_list } from "./coinpair_list.js"
export default {
    data() {
        return {
            configData: {},
            configDetailData: {},
            config_form: [
                {
                    label: this.$i18n.t("arbitrage.max_order"),
                    value: 'max_order',
                    type: 'number',
                    required: true
                },
                {
                    label: this.$i18n.t("arbitrage.pnl"),
                    value: 'pnl',
                    filter: 'percent',
                    type: 'number',
                    required: true
                },
                {
                    label: this.$i18n.t("arbitrage.price_range"),
                    value: 'price_range',
                    filter: 'percent',
                    type: 'number',
                    required: true
                },
                {
                    label: this.$i18n.t("arbitrage.loop"),
                    value: 'loop',
                    type: 'number',
                    required: true
                },
            ],
            rules: {},
            spot_exchange_list:[],
            future_exchange_list:[],
            apikey_list: [],
            exchange_1_apikey_list: [],
            exchange_2_apikey_list: [],    
            coinpair_list: coinpair_list,
            telegram_list: [],
            symbols_list: [],
            pair_list: [],
            select_telegram_id: null,
            is_apikey_loading: true,
            is_symbols_loading: true,
            is_stand_price_loading: false,
            is_funding_rate_loading: false,
            error_log: '',
            is_edit: false,
        }
    },
    watch: {
        'configData.coin_pair': 'handleCoinPairSplit',
    },
    async mounted() {
        await this.getExchange()
        await this.getTelegram()
    },
    methods: {
        async getTelegram() {
            try {
                const res = await this.$http.get('/api/v1/telegram/')
                for (const o of res.data) {
                    this.telegram_list.push({
                        label: o.name,
                        value: o.id
                    })
                }
            } catch (e) {
                console.error(e)
            }
        },
        async getExchange() {
            const etypes = ['spot', 'future'];
            try {
                const requests = etypes.map(async etype => {
                    const res = await this.$http.get(`/api/v1/info/exchange/${etype}`);
                    return Object.entries(res.data).map(([key, value]) => ({ label: key, value }));
                });

                const results = await Promise.all(requests);

                etypes.forEach((etype, index) => {
                    this[`${etype}_exchange_list`] = results[index];
                });
            } catch (e) {
                console.error(e);
            }
        },
        async changeExchangeSelect(v, type) {
            await this.get_apikeys(v, type);
            
            // TODO 실 데이터 쿼리 및 바인딩 작업 필요
            // await this.get_symbols(v)
        },
        handleCoinPairSplit(){
            if(this.configData.coin_pair.includes('/')){
                let symbol_split= '/'
                const [coin, pair] = this.configData.coin_pair.split(symbol_split)
                this.configData.coin = coin
                this.configData.pair = pair
            }
            this.getTicker();
            this.getBalance();
            this.getFundingRate();
        },
        async get_apikeys(v, type){
            try {
                this.apikey_list = []
                this.is_apikey_loading = true
                const res = await this.$http.get('/api/v1/apikeys/', { params:{exchange: v}});
                this.apikey_list = res.data.map(e => ({
                    label: e.name,
                    value: e.id
                }));
                if (type === 'exchange_1' || type === 'exchange_2') {
                    this[type + '_apikey_list'] = this.apikey_list;
                }
                this.is_apikey_loading = false
            } catch (e) {
                console.error(e)
            }
        },
        // async get_symbols(exchange){
        //     this.symbols_list = []
        //     this.is_symbols_loading = true
        //     const res = await this.$http.get(`/api/v1/exchange/${exchange}/symbols`)
        //     let symbol_split = ''
        //     if (res.data[0].includes('/')) {
        //         symbol_split = '/'
        //     } else if (res.data[0].includes('_')) {
        //         symbol_split = '_'
        //     }
        //     if (symbol_split !== '') {
        //         this.pair_list = []
        //         res.data.forEach(e => {
        //             const _symbol = e.split(symbol_split)
        //             if (!this.symbols_list.find(s => s.label === _symbol[0])) {
        //                 this.symbols_list.push(
        //                     {
        //                         label: _symbol[0],
        //                         value: _symbol[0].toLowerCase()
        //                     }
        //                 )
        //             }
        //             if (!this.pair_list.find(s => s.label === _symbol[1])) {
        //                 this.pair_list.push({
        //                     label: _symbol[1],
        //                     value: _symbol[1].toLowerCase()
        //                 })
        //             }

        //         });
        //     } else {
        //         res.data.forEach(e => {
        //             this.symbols_list.push(
        //                 {
        //                     label: e,
        //                     value: e.toLowerCase()
        //                 }
        //             )
        //         });
        //     }
        //     this.is_symbols_loading = false

        //     return res.data
        // },
        async getTicker(){
            this.is_stand_price_loading = true

            const symbol = `${this.configData.coin}_${this.configData.pair}`
            const ticker_1 = await this.$http.get(`/api/v1/exchange/${this.configData.exchange_1}/lastticker/${symbol.toUpperCase()}`);
            const ticker_2 = await this.$http.get(`/api/v1/exchange/${this.configData.exchange_2}/lastticker/${symbol.toUpperCase()}`);
            
            this.configData.stand_price_1 = ticker_1.data.last.toFixed(2);
            this.configData.stand_price_2 = ticker_2.data.last.toFixed(2);
            // this.configData.percentage_change = (((this.configData.stand_price_2 - this.configData.stand_price_1) / this.configData.stand_price_2) * 100).toFixed(2);

            const price_diff = (this.configData.stand_price_1 - this.configData.stand_price_2).toFixed(2);
            const price_diff_ratio = (((this.configData.stand_price_1 - this.configData.stand_price_2) / this.configData.stand_price_1) * 100).toFixed(2);

            this.configData.price_calc = `${price_diff}(${price_diff_ratio}%)`;

            this.is_stand_price_loading = false;
        },
        async getFundingRate(){
            this.is_funding_rate_loading = true
            
            const symbol = `${this.configData.coin}_${this.configData.pair}`
            const res = await this.$http.get(`/api/v1/exchange/${this.configData.exchange_2}/fundingrate/${symbol}`)
            this.configData.funding_rate = res.data.fundingRate

            this.is_funding_rate_loading = false
        },
        async getBalance(){
            this.is_balance_loading = true

            const symbol = `${this.configData.coin}_${this.configData.pair}`

            const res = await Promise.all([
                this.$http.post(`/api/v1/exchange/${this.configData.exchange_1}/balance/`, {
                    apikeyId: this.configData.apikey_1,
                    symbol: symbol.toUpperCase(),
                }),
                this.$http.post(`/api/v1/exchange/${this.configData.exchange_2}/balance/`, {
                    apikeyId: this.configData.apikey_2,
                    symbol: symbol.toUpperCase(),
                })
            ])

            res.map((response, i) => {
                const balance = response.data;
                let keys = Object.keys(balance);
                let coin_name = keys[0]; 
                let pair_name = keys[1]; 

                let coin = balance[coin_name];
                let pair = balance[pair_name];
                if (i === 1) {
                    this.configData[`balance_${i + 1}`] = `${coin.free}`;    
                } else {
                    this.configData[`balance_${i + 1}`] = `${coin.free}/${pair.free}`;
                }
            });

            this.is_balance_loading = false
        },
        async emitBtn(){
            await this.registArbitrage()
        },
        async registArbitrage(){
            const arbitrage = {
                ...this.configData,
                telegram_id: this.select_telegram_id,
                config: {...this.configDetailData }
            }
            try{
                if (this.is_edit === true){
                    const res = await this.$http.put(`/api/v1/autotrade/arbitrage2/${this.configData._id}`, arbitrage)
                    this.error_log = ''
                } else {
                    const res = await this.$http.post('/api/v1/autotrade/arbitrage2/', arbitrage)
                    this.error_log = ''
                }
                this.$router.go();
            } catch (e){
                console.error(e.response)
                this.error_log = e.response.data.message
            }
        },
        createEdit(record){
            this.is_edit = true
            this.configData = record
            this.configDetailData = record.config
            this.select_telegram_id = record.telegram_id
            this.changeExchangeSelect(record.exchange_1, 'exchange_1')
            this.changeExchangeSelect(record.exchange_2, 'exchange_2')
        },
        createAdd(){
            if(this.is_edit === true) {
                this.is_edit = false
                this.clearForm()
            }
        },
        clearForm(){
            this.configData = {}
            this.configDetailData = {}
        }

    },
    
}
</script>
<style lang="scss">

.ant-form-item {
    margin-bottom: 5px;
}
    
</style>